import React, { useEffect } from 'react';
import Modal from 'react-modal';

import Page from '../components/Page';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import useStoryblok from '../utils/storyblok';
import { SEO } from '../components/Seo';
import { regionDetectEffect } from '../templates/page';
import { GTM, GTMNoScript } from '../components/Gtm';
import { PardotTracker } from '../components/PardotTracker';

// This file applies to the home page at the root level only
// All other pages are created use the /templates/page.js file
// TODO: investigate if we can merge this with the template page
// so we don't need to duplicate code in two places
export default function PageIndex({ data, location }) {
  const story = useStoryblok(data.story, location);
  useEffect(() => regionDetectEffect(story.full_slug), []);

  // The below line is to allow react-modal to set accessibility attribute
  // https://reactcommunity.org/react-modal/accessibility/#app-element
  if (typeof document !== 'undefined') {
    Modal.setAppElement(document.querySelector('body'));
  }
  return (
    <>
      <GTMNoScript />
      <Layout location={location} hideNavigation={story.content.noNavigation}>
        <Page blok={story.content} location={location} />
      </Layout>
    </>
  );
}
export const Head = ({ data }) => {
  const { title, description } = data.story?.content?.meta || {};
  return (
    <>
      <GTM />
      <PardotTracker />
      <SEO title={title} description={description} />
    </>
  );
};
export const query = graphql`
  {
    story: storyblokEntry(full_slug: { eq: "global/" }) {
      name
      content
      full_slug
      uuid
    }
  }
`;
