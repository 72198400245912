import React from 'react';

export const GTM = () => {
  const gtm = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-5TJVXKNG');`;

  return <script type="text/javascript" dangerouslySetInnerHTML={{ __html: gtm }} />;
};

export const GTMNoScript = () => {
  const gtmNoScript = 'https://www.googletagmanager.com/ns.html?id=GTM-5TJVXKNG';
  return (
    <noscript>
      <iframe src={gtmNoScript} height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe>
    </noscript>
  );
};
