import React, { useEffect } from 'react';
import axios from 'axios';
import { navigate } from 'gatsby';
import Modal from 'react-modal';
import Page from '../components/Page';
import Layout from '../components/layout';
import useStoryblok from '../utils/storyblok';
import { SEO } from '../components/Seo';
import rewriteSlug from '../utils/rewriteSlug';
import { GTM, GTMNoScript } from '../components/Gtm';
import { PardotTracker } from '../components/PardotTracker';

export const regionDetectEffect = (fullSlug) => {
  const pageRegion = fullSlug.split('/')[0];
  const pagePath = fullSlug.replace(`${pageRegion}/`, '/');
  const getRegion = async () => {
    try {
      // Below only work in non-local environment because the headers are set
      // by the CDN(Fastly). For local test, you need to set local storage item manually
      const res = await axios('/ping.json');
      const clientRegion = res.headers['x-client-bc-region']?.toLocaleLowerCase();
      // At the moment, we use 'global' as region name for au
      const siteRegion = clientRegion === 'au' ? 'global' : clientRegion;

      if (siteRegion) {
        localStorage.setItem('selectedRegion', siteRegion);
        if (siteRegion !== pageRegion) {
          navigate(rewriteSlug(`/${siteRegion}${pagePath}`));
        }
      }
    } catch (error) {
      console.log('Unable to get region', error);
    }
  };
  const selectedRegion = localStorage.getItem('selectedRegion');
  if (!selectedRegion || !['global', 'nz', 'uk'].includes(selectedRegion)) {
    localStorage.removeItem('selectedRegion');
    getRegion();
  } else if (selectedRegion && selectedRegion !== pageRegion) {
    navigate(rewriteSlug(`/${selectedRegion}${pagePath}`));
  }
};

export default function PageIndex({ pageContext, location }) {
  const story = useStoryblok(pageContext.story, location);
  const { full_slug } = story;
  useEffect(() => regionDetectEffect(full_slug), []);
  // The below line is to allow react-modal to set accessibility attribute
  // https://reactcommunity.org/react-modal/accessibility/#app-element
  if (typeof document !== 'undefined') {
    Modal.setAppElement(document.querySelector('body'));
  }

  return (
    <>
      <GTMNoScript />
      <Layout location={location} hideNavigation={story.content.noNavigation}>
        <Page blok={story.content} location={location} noNavigation={story.content.noNavigation} />
      </Layout>
    </>
  );
}
export const Head = ({ pageContext }) => {
  const { title, description } = pageContext?.story?.content?.meta || {};
  return (
    <>
      <GTM />
      <PardotTracker />
      <SEO title={title} description={description} />
    </>
  );
};
